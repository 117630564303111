function CloseIconSvg({ color }) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.000000 430.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,430.000000) scale(0.100000,-0.100000)" fill={color !== null || color !== undefined ? color : '#000000'} stroke="none">
                <path d="M597 4279 c-21 -5 -50 -15 -65 -23 -15 -8 -131 -119 -258 -248 -253 -255 -258 -262 -257 -374 1 -119 -20 -95 708 -824 366 -366 665 -670 665 -675 0 -6 -300 -309 -666 -675 -506 -506 -671 -676 -687 -710 -13 -30 -20 -65 -20 -110 0 -113 17 -138 256 -378 117 -117 230 -223 252 -235 58 -32 182 -31 245 2 31 16 253 231 713 689 l667 667 673 -672 c369 -369 687 -679 706 -688 53 -27 139 -31 207 -10 55 17 72 31 268 224 276 273 266 258 266 388 0 85 -4 111 -21 144 -12 25 -223 246 -547 573 -289 292 -587 593 -661 669 l-136 137 667 668 c366 367 673 681 682 699 22 44 22 209 0 261 -18 44 -399 432 -459 467 -66 39 -133 49 -206 31 -33 -9 -75 -26 -93 -37 -17 -12 -327 -317 -688 -678 l-658 -656 -667 667 c-523 522 -678 671 -713 687 -57 24 -124 32 -173 20z" />
            </g>
        </svg>
    )
}

export default CloseIconSvg;